import './App.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Route, Routes } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';

import Layout from './Layout';
import AuthWrapper from './AuthWrapper';

import Home from './Home';

import Login from './Login';
import Signup from './Signup';
import Logout from './Logout';

import Ingest from './Ingest/Ingest';
import NotFound from './NotFound';
import Root from './Root/Index';
import Prompts from './Root/Prompts';
import Roadbooks from './Root/Roadbooks';

import Roadbook from './Roadbook/Roadbook';
import Settings from './Settings';

import { i18n, getBrowserLanguage } from "./utils/i18n";
import frenchDictionnary from "./i18n/fr.json";
import { useEffect, useState } from 'react';

const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: '#ff9933',
  //   },
  //   success: {
  //     main: '#c8e378',
  //   },
  //   error: {
  //     main: '#c2352a',
  //   },
  // }
  palette: {
    primary: {
      main: '#51AFC9',
    },
  }
});


function withLayout(Component, props) {
  return (
    <ThemeProvider theme={theme}>
      <Layout {...props}>
        <AuthWrapper>
          <Component {...props} />
        </AuthWrapper>
      </Layout>
    </ThemeProvider>
  );
}

function App() {
  const [currentLocale, setCurrentLocale] = useState('en');

  useEffect(() => {
    const locale = getBrowserLanguage();

    if (locale !== 'fr') return;

    i18n.load(frenchDictionnary);
    setCurrentLocale(currentLocale);
  }, []);

  return (
    <div className="App" key={currentLocale}>

      <Router>
        <Routes>
          <Route exact path="/" element={withLayout(Home)} />

          <Route exact path="/login" element={withLayout(Login)} />
          <Route exact path="/signup" element={withLayout(Signup)} />
          <Route exact path="/logout" element={withLayout(Logout)} />
          <Route exact path="/settings" element={withLayout(Settings)} />

          <Route exact path="/root" element={withLayout(Root)} />
          <Route exact path="/root/prompts" element={withLayout(Prompts)} />
          <Route exact path="/root/roadbooks" element={withLayout(Roadbooks)} />

          <Route exact path="/ingest" element={withLayout(Ingest)} />
          <Route exact path="/roadbooks/:id" element={withLayout(Roadbook)} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
