import { Component } from 'react';
import { getWindRelativeDirection } from './utils';
import { getNearestWeather } from './utils';
import _t, { getBrowserLanguage } from '../../utils/i18n';

let lastX = 0;

class CustomizedTick extends Component {
  constructor(props) {
    const ascents = {};

    props.ascents.forEach(ascent => {
      ascents[ascent.start_km] = { position: 'start', ascent };
      ascents[ascent.end_km] = { position: 'end', ascent };
    });

    super(props);

    this.ascents = ascents;

    this.start = props.kms[0];
    this.end = props.kms[props.kms.length - 1];
  }

  render() {
    const { ascents, start, end } = this;
    let { x, y, payload, index, visibleTicksCount, kms } = this.props;

    const { value } = payload;
    const elems = [];

    // weather
    if (
      index === 0 && kms[0]?.w ||
      (kms[value]?.w && kms[value].km < kms.length - 5) ||
      (index === visibleTicksCount - 1 && kms[kms.length - 1]?.w)
    ) {
      let w, posX, anchor;

      switch (index) {
        case 0:
          w = kms[0]?.w;
          anchor = 'start';
          posX = 20;
          break;
        case visibleTicksCount - 1:
          w = kms[kms.length - 1]?.w;
          anchor = 'end';
          posX = -20;
          break;
        default:
          w = kms[value]?.w;
          anchor = 'middle';
          posX = 0;
          break;
      }

      elems.push(
        <g key={`weather-${x}-${y}`} transform={`translate(${x},${y})`}>

          <text x={posX} y={40} dy={14} className="small" textAnchor={anchor} fill="grey">
            {`${w.temp}°C - ${w.weather}`}
          </text>

        </g>
      );
    }

    const nearestWeather = getNearestWeather(Math.round(value), kms);

    // wind
    // console.log('wind', lastWeather, kms[value])
    if (nearestWeather && kms[Math.round(value)]) {
      // get wind direction
      const windDirection = getWindRelativeDirection(nearestWeather.wind_direction, kms[Math.round(value)].o);
      let color;

      // console.log(windDirection)
      switch (windDirection) {
        case 'front':
          color = '#5a00b3';
          break;
        case 'back':
          color = '#e67300';
          break;
        case 'lateral_back':
          color = '#ff9933';
          break;
        case 'lateral_front':
          color = '#8000ff';
          break;
        case 'lateral':
        default:
          color = "gray";
          break;
      }

      // if first tick add /Users/guillaumepotier/Downloads/windwock.svg windsock image
      if (index === 0) {
        const lang = getBrowserLanguage();
        const posX = lang === 'fr' ? 15 : 0;

        elems.push([
          <g key={`thermostat-${x}-${y}`} transform={`translate(${x},${y})`}>
            <image href="/temperature.svg" x={-10} y={40} width={16} height={16} fill="#fff" style={{ color: "red" }} />
          </g>,
          <g key={`wind-${x}-${y}`} transform={`translate(${x},${y})`}>
            <image href="/windsock.svg" x={-25} y={52} width={16} height={16} fill="#fff" style={{ color: "red" }} />
          </g>,
          <g key={`direction-${x}-${y}`} transform={`translate(${x},${y})`}>
            <text x={-25} y={67} dy={14} className="small" textAnchor="start" fill="grey">
              {_t("Headwind")}
            </text>
            <rect x={posX + 35} y={75} width={7} height={7} className="small" textAnchor="start" fill="#5a00b3" />
            <rect x={posX + 42} y={75} width={7} height={7} className="small" textAnchor="start" fill="#8000ff" />
            <rect x={posX + 49} y={75} width={7} height={7} className="small" textAnchor="start" fill="gray" />
            <rect x={posX + 56} y={75} width={7} height={7} className="small" textAnchor="start" fill="#ff9933" />
            <rect x={posX + 63} y={75} width={7} height={7} className="small" textAnchor="start" fill="#e67300" />
            <text x={posX + 75} y={67} dy={14} className="small" textAnchor="start" fill="grey">
              {_t("Backwind")}
            </text>
          </g>
        ]);
      }


      if (index % 20 === 0 && nearestWeather?.wind > 5) {
        elems.push([
          <g key={`wind-${color}-${x}-${y}`} transform={`translate(${x},${y})`}>
            <image href="/wind.svg" x={-25} y={67} width={16} height={16} fill="#fff" style={{ color: "red" }} />
          </g>
        ]);
      }

      if (x - lastX > 0)
        elems.push([
          <g key={`wind-${color}-${x}-${y}`} transform={`translate(${x},${y})`}>
            <rect x={0} y={59} width={x-lastX} height={7} className="small" textAnchor="start" fill={color} />
          </g>
        ]);

      lastX = x;
    }

    // start / end
    if (index === 0 && start?.p?.length) {
      elems.push([
        <g key={`${x}${y}`} transform={`translate(${x},${y})`}>
          <text x={-50} y={20} dy={16} textAnchor="start" className="bold" fill="#666">
            {start?.p[0]}
          </text>
        </g>
      ]);
    }

    if (index === visibleTicksCount - 1) {
      elems.push([
        <g key={`${x}${y}`} transform={`translate(${x},${y})`}>
          <text x={15} y={-15} dy={16} textAnchor="middle" fill="#666">
            {Math.round(value)}
          </text>
        </g>
      ]);

      if (end?.p?.length) {
        elems.push([
          <g key={`${x}${y}`} transform={`translate(${x},${y})`}>
            <text x={30} y={20} dy={16} textAnchor="end" className="bold" fill="#666">
              {end?.p[0]}
            </text>
          </g>
        ]);
      }

      return elems;
    }

    // detect if value is a float or an int
    // if float, we don't display it
    const MIN_DISTANCE = Math.round(10 / 200 * kms.length);

    if (value !== 0 && kms.length - value > MIN_DISTANCE && value % 1 === 0) {
      if (kms[value]?.p) {

        // iterate over ascents to check if there is one too close before or after
        // if so, don't display the locality
        for (let asc in ascents) {
          if (asc > value && asc - value < MIN_DISTANCE)
            return elems;
          if (asc < value && value - asc < MIN_DISTANCE)
            return elems;
        }

        elems.push([
          <g style={{ zIndex: 9 }} key={`city-${x}-${y}`} transform={`translate(${x},${y})`}>
            <path
              d={`M0,0L0,0M0,${-50}L0,0`}
              stroke="#ccc"
              strokeWidth={1}
              fill="none"
            />

            <text x={55} y={-10} dy={14} className="small" textAnchor="start" fill="#666" transform="rotate(-90)">
              {kms[value]?.p[0]}
            </text>

            <text x={0} y={0} dy={16} className="small" textAnchor="middle" fill="#666">
              {Math.round(value)}
            </text>

          </g>
        ]);

        return elems;
      }
    }

    // every remarquable ascent
    if (!ascents[value] && index !== visibleTicksCount - 1) return elems;
    if (!ascents[value]) return elems;

    const { ascent, position } = ascents[value];

    // end of ascent
    if (position === 'end') {
      // do not display too close to the end since we already have marker there
      if (value <= kms.length - 7) {
        elems.push([
          <g key={`${x}${y}`} transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} className="small" textAnchor="middle" fill="#666">
              {Math.round(value)}
            </text>
          </g>
        ]);
      }

      const isHard = (ascent.category === 'hc' || ascent.category <= 4);

      elems.push([
        <g style={{ zIndex: 9 }} key={`cross-${x}-${y}`} transform={`translate(${x},${y})`}>

          <path
            d={`M0,0L0,0M0,${-320}L0,0`}
            stroke="#c96b51"
            strokeWidth={1}
            fill="none"
          />

          <text x={265} y={0} dy={14} className="small" textAnchor="start" fill="#666" transform="rotate(-90)">
            {!!ascent.end_places?.length && `${Math.round(ascent.end_alt)}m - ${ascent.end_places[0]}`}
            {!ascent.end_places?.length && `${Math.round(ascent.end_alt)}m`}
          </text>

          <text x={265} y={15} dy={14} className="small bold" textAnchor="start" fill="#666" transform="rotate(-90)">
            {`(${ascent.distance_km}km à ${ascent.percent}%)` }
          </text>

          {isHard && (
            <g transform={`translate(8,-260)`}>
              <rect x="0" y="0" width="20" height="20" fill="red"></rect>
              <text x={ascent.category === 'hc' ? 3 : 6} y="14" fontFamily="Verdana" fontSize="12" fill="white">{ascent.category}</text>
            </g>
          )}

        </g>
      ]);

      return elems;
    }

    // start of ascent
    if (position === 'start') {
      // do not display too close to the end since we already have marker there
      if (value <= kms.length - 7) {
        elems.push([
          <g key={`${x}${y}`} transform={`translate(${x},${y})`}>
            <text x={0} y={12} dy={16} className="small" textAnchor="middle" fill="#666">
              {Math.round(value)}
            </text>
          </g>
        ]);
      }

      elems.push(
        <g style={{ zIndex: 9 }} key={`cross-${x}-${y}`} transform={`translate(${x},${y})`}>

          <path
            d={`M0,0L0,0M0,${-170}L0,0`}
            stroke="#51AFC9"
            strokeWidth={1}
            fill="none"
          />

          <text x={130} y={-18} dy={14} className="small" textAnchor="start" fill="#666" transform="rotate(-90)">
            {!!ascent.start_places?.length && `${Math.round(ascent.start_alt)}m - ${ascent.start_places[0]}`}
            {!ascent.start_places?.length && `${Math.round(ascent.start_alt)}m`}
          </text>

        </g>
      );

      return elems;
    }

    return elems;
  }
}

export default CustomizedTick;