import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthentifiedFetch } from "./utils/hooks";
import { API_BASE_URL } from "./config";
import _t from "./utils/i18n";
import { useNavigate } from "react-router-dom";

const Roadbook = ({ roadbook }) => {
  const navigate = useNavigate();

  return (
    <Box key={roadbook.id} sx={{
      background: "white",
      border: "1px solid #ccc",
      padding: "10px",
      marginBottom: "10px",
      textAlign: "left",
      cursor: "pointer",
    }}
    onClick={() => navigate(`/roadbooks/${roadbook.id}`)}
    >
      <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>{roadbook.name || _t("Unnamed ride")}</Typography>
      <Box>
        {roadbook.distance}km - {roadbook.elevation}m D+
      </Box>
    </Box>
  )
};

const History = () => {
  const fetchRoadbooks = useAuthentifiedFetch();

  const [previous, setPrevious] = useState([]);
  const [thisWeek, setThisWeek] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const url = API_BASE_URL.clone().segment('roadbooks').toString();
      const response = await fetchRoadbooks(url);

      if (!response.ok) {
        console.log('fetch roadbooks failed');
        setLoading(false);
        return;
      }

      const { roadbooks } = await response.json();

      // iterate over roadbooks to sort them into previous, this week (mon - sun) and upcoming
      const now = dayjs();
      const previous = [];
      const thisWeek = [];
      const upcoming = [];

      for (const roadbook of roadbooks) {
        const start = dayjs(roadbook.ride_date || roadbook.created_at);

        if (start.isBefore(now, 'day')) {
          previous.push(roadbook);
        } else if (start.isBefore(now, 'week')) {
          thisWeek.push(roadbook);
        } else {
          upcoming.push(roadbook);
        }
      }

      setPrevious(previous);
      setThisWeek(thisWeek);
      setUpcoming(upcoming);

      setLoading(false);
    };

    fetch();
  }, []);


  return (
    <Box textAlign="left" className="History">

      {loading && <Typography>{_t("Loading...")}</Typography>}

      {thisWeek.length > 0 && <Typography sx={{ fontWeight: "bold", marginBottom: "10px" }}>{_t("This week")}</Typography>}
      {thisWeek.map((roadbook) => (
        <Roadbook key={roadbook.id} roadbook={roadbook} />
      ))}

      {thisWeek.length > 0 && upcoming.length > 0 && <Box mb={2} />}

      {upcoming.length > 0 && <Typography sx={{ fontWeight: "bold", marginBottom: "10px" }}>{_t("Upcoming")}</Typography>}
      {upcoming.map((roadbook) => (
        <Roadbook key={roadbook.id} roadbook={roadbook} />
      ))}

      {upcoming.length > 0 && previous.length > 0 && <Box mb={2} />}

      {previous.length > 0 && <Typography sx={{ fontWeight: "bold", marginBottom: "10px" }}>{_t("Older")}</Typography>}
      {previous.map((roadbook) => (
        <Roadbook key={roadbook.id} roadbook={roadbook} />
      ))}
    </Box>
  )
}

export default History;