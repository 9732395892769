import _t from "./utils/i18n";
import { useState } from "react";

import { Box, Button, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { selectAuth0User, selectUser, selectUserMetadata } from "./selectors";
import { useAuth0 } from "@auth0/auth0-react";

const Header = () => {
  const {
    logout,
    loginWithRedirect,
  } = useAuth0();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const auth0User = useSelector(selectAuth0User);

  const metadata = useSelector(selectUserMetadata);
  const is_root = metadata?.is_root;

  return (
    <Box className="Header" sx={{
      position: "fixed",
      display: "flex",
      alignItems: "center",
      height: "50px",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      borderBottom: "1px solid #eee",
      background: "#fff",
      boxShadow: "0 1px 2px rgba(0,0,0,.1)",
      padding: { xs: '0 10px', md: '0 25px' }
    }}>
      <Box className="Title" sx={{ display: "flex", flex: "1 auto" }}>
        <Link to="/" style={{ color: "#000", textDecoration: "none" }}>
          <Box sx={{ fontWeight: "bold" }}>GPXPLORER</Box>
        </Link>
        <Box sx={{ fontSize: "11px", marginLeft: "3px" }}>Beta</Box>
      </Box>
      <Box className="Actions" sx={{ display: "flex", alignItems: "center" }}>

        {user && <Button component={Link} to="/ingest" variant="contained" color="primary" sx={{ color: "#fff", marginRight: "10px", textTransform: "none" }}>{_t("+ new roadbook")}</Button>}

        <Box sx={{ ml: "5px" }}>

          <Box>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MenuIcon sx={{ color: "#000" }} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {!auth0User && <MenuItem onClick={() => loginWithRedirect()}>{_t("Login")}</MenuItem>}
              {user && <MenuItem onClick={() => navigate('/settings')}>{_t("Settings")}</MenuItem>}
              {auth0User && <MenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin }})}>{_t("Logout")}</MenuItem>}
              {is_root && <MenuItem onClick={() => navigate('/root')}>Root</MenuItem>}
            </Menu>
          </Box>

        </Box>


      </Box>
    </Box>
  )
};

export default Header;