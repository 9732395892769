import { createStore } from 'redux'
import basil from './utils/basil';

const defaultState = {
  userHash: basil.get('userHash') || null,
  roadbookHash: basil.get('roadbookHash') || null,
  access_token: null,
  ingesting: false,
  user: null,
  auth0User: null,
  userInfo: {},
  userMetadata: {},
}

function appReducer(state = defaultState, action) {
  switch (action.type) {
    case 'access_token/set':
      return { ...state, access_token: action.payload }
    case 'user/set':
      return { ...state, user: action.payload }
    case 'user/auth0/set':
      return { ...state, auth0User: action.payload }
    case 'userInfo/set':
      return { ...state, userInfo: action.payload }
    case 'userMetadata/set':
      return { ...state, userMetadata: action.payload }
    case 'user/unset':
      return { ...state, user: null, userInfo: null, userMetadata: null, access_token: null, auth0User: null }
    case 'userHash/set':
      basil.set('userHash', action.payload);
      return { ...state, userHash: action.payload }
    case 'roadbookHash/set':
      basil.set('roadbookHash', action.payload);
      return { ...state, roadbookHash: action.payload }
    case 'ingest/set':
      basil.set('ingesting', action.payload);
      return { ...state, ingesting: action.payload }
    case 'ingest/end':
      basil.remove('ingesting');
      return { ...state, ingesting: false }
    case 'ingest/unsetAll':
      basil.remove('userHash');
      basil.remove('roadbookHash');
      return { ...state, userHash: null, roadbookHash: null }
    default:
      return state
  }
}

export const store = createStore(appReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())