import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";

import _t from "../utils/i18n";
import { useEffect, useState } from "react";
import { Box, Typography, Button, Input, TextField, MenuItem, Select, InputLabel, FormControl, FormHelperText } from "@mui/material";
import { API_BASE_URL } from "../config";
import Layout from "./Layout";
import { useSelector } from "react-redux";
import { selectUser } from "../selectors";
import { useProcessRoadbook } from "../utils/hooks";

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

let once = false;

const Create = ({ roadbook, onCreate, editMode }) => {
  const initialRideDate = roadbook?.ride_date ? dayjs(roadbook.ride_date) : dayjs().add(1, 'day');

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(roadbook?.name || '');
  const [error, setError] = useState(null);
  const [rideDate, setRideDate] = useState(initialRideDate.format('DD/MM/YYYY'));
  const [currentWeight, setCurrentWeight] = useState(roadbook?.current_weight || '');
  const [currentFitness, setCurrentFitness] = useState(roadbook?.current_fitness || '');

  const user = useSelector(selectUser);
  const onProcess = useProcessRoadbook(roadbook);

  const onEdit = async (data) => {
    setLoading(true);

    const url = API_BASE_URL
      .clone()
      .segment('roadbooks')
      .segment(roadbook.id)
      .toString();

    if (!(data.name || '').trim().length || !data.ride_date || !data.current_weight || !data.current_fitness) {
      setError(_t("All fields are required"));
      setLoading(false);
      return;
    }

    // transform data.ride_date to YYYY-MM-DD
    // validate date format REGEX ^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$
    if (!data.ride_date.match(/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/)) {
      setError(_t("Invalid date format"));
      setLoading(false);
      return;
    }

    if (data.current_weight < 30 || data.current_weight > 200) {
      setError(_t("Invalid weight"));
      setLoading(false);
      return;
    }

    const dateParts = data.ride_date.split('/');
    data.ride_date = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

    try {
      const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
      });

      if (!response.ok) {
        setError(_t("Creation failed"));
        setLoading(false);
        return;
      }

      // otherwise, update roabook to go to OneMoreThing screen
      const { roadbook } = await response.json();

      onCreate(roadbook);
    } catch (e) {
      console.log('error', e);
      setError(_t("Creation failed"));
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!roadbook) return;
    if (once) return;

    if (!roadbook.parsed_json) {
      console.log('processing biatch');
      once = true;

      onProcess('convert');
    }
  }, [roadbook]);

  const tooFarAway = dayjs(rideDate, 'DD/MM/YYYY').isAfter(dayjs().add(5, 'day'));

  return (
    <Layout step={2} editMode={editMode}>
      <Box>
        {!editMode && (
          <Box className="Title" sx={{ textAlign: "left" }}>
            <Typography variant="h4">{_t("Create a roadbook for your next ride")}</Typography>
            <Typography>{_t("Submit your details to finish your roadbook personalization.")}</Typography>
          </Box>
        )}

        <Box>
          <Box mt={4}>
            <TextField
              variant="outlined"
              label={_t("Roadbook name")}
              fullWidth
              value={name}
              placeholder={`Unnamed ride ${roadbook?.distance}km - ${roadbook?.elevation}m D+`}
              onChange={(e) => setName(e.target.value)}
              helperText={!editMode && _t("Give your roadbook a unique name that you can easily recognize and refer to later")}
            />
          </Box>

          <Box mt={4}>
            <TextField
              label={_t("Ride date")}
              variant="outlined"
              fullWidth
              placeholder="dd/mm/yyyy"
              value={rideDate}
              onChange={(e) => setRideDate(e.target.value)}
              helperText={!editMode && _t("Please select the date of your planned ride. Choosing a date within the next 5 days allows us to provide accurate weather forecasts for each stage of your ride. If you select a date beyond 5 days from now, please note that weather forecasts won't be available.")}
            />
            {tooFarAway && (
              <Box sx={{ fontSize: "12px", color: "orange", marginTop: "5px", textAlign: "left" }}>{_t("The ride date is too far in the future, we won't have any meteorological data for it. Re-generate your roadbook 5 days before your ride to have climate advices.")}</Box>
            )}
          </Box>

          <Box mt={4}>
            <TextField
              variant="outlined"
              label={_t("Current weight")}
              fullWidth
              value={currentWeight}
              placeholder={_t("Enter your weight in kg")}
              onChange={(e) => setCurrentWeight(e.target.value)}
              helperText={!editMode && _t("Knowing your weight assists in personalizing nutrition and exertion level advice during the ride.")}
            />
          </Box>

          <Box mt={4}>
            <FormControl fullWidth>
              <InputLabel id="gender">{_t("Current fitness level")}*</InputLabel>
              <Select
                sx={{ textAlign: "left" }}
                value={currentFitness}
                label="gender"
                required
                onChange={(e) => setCurrentFitness(e.target.value)}
              >
                <MenuItem value="very_low">{_t("Very low")}</MenuItem>
                <MenuItem value="low">{_t("Low")}</MenuItem>
                <MenuItem value="medium">{_t("Medium")}</MenuItem>
                <MenuItem value="high">{_t("High")}</MenuItem>
                <MenuItem value="very_high">{_t("Very High")}</MenuItem>
              </Select>
              <FormHelperText>{!editMode && _t("Your current fitness level helps us provide more accurate and relevant advice and recommendations in your roadbook.")}</FormHelperText>
            </FormControl>
          </Box>

          {error && (
            <Box mt={4}>
              <Typography variant="body1" sx={{ color: "red" }}>{error}</Typography>
            </Box>
          )}

          <Box mt={4} sx={{ textAlign: editMode ? "center" : "left" }}>
            {editMode && (
              <Button onClick={() => onCreate(roadbook)}>{_t("Close")}</Button>
            )}

            <Button
              sx={{ color: "#fff", marginLeft: "10px" }}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => onEdit({
                name,
                ride_date: rideDate,
                current_weight: currentWeight,
                current_fitness: currentFitness
              })}
              disabled={loading}
            >{editMode ? _t("Edit roadbook") : _t("Generate roadbook")}</Button>
          </Box>

        </Box>
      </Box>
    </Layout>
  )
};

export default Create;