import { Box } from "@mui/material";
import FirstTime from "./Ingest/FirstTime";

const Settings = () => {
  return (
    <Box>
      <FirstTime editMode />
    </Box>
  );
};

export default Settings;