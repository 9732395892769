import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = ({ logout, isAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated)
      return navigate('/');

    logout();
  }, []);

  return null;
};

export default Logout;