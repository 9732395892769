import Box from '@mui/material/Box';

import Header from './Header';
import { useDispatch } from 'react-redux';

const Layout = ({ children }) => {
  return (
    <Box className="Layout" sx={{ minHeight: "100vh", background: "#F9F6F1" }}>

      <Header />

      <Box className="Content" display="flex" flexDirection="column" sx={{ pt: { xs: '50px', md: '50px' }, minHeight: '90vh', alignItems: "center" }}>
        {children}
      </Box>
    </Box>
  );
}

export default Layout;
