import _t from "./utils/i18n";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectAuth0User, selectUser } from "./selectors";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import basil from "./utils/basil";
import History from "./History";

const Home = ({ loginWithRedirect }) => {
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const auth0User = useSelector(selectAuth0User);

  useEffect(() => {
    const redirect = basil.get('redirect');
    if (!redirect) return;

    basil.remove('redirect');
    navigate(redirect);
  }, []);

  return (
    <Box mt={4}>

      {!user && (
        <Box mt={4}>
          <Box sx={{
            width: "90vw",
            maxWidth: "700px",
          }}>
            <Box sx={{
              height: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              background: `url(${process.env.PUBLIC_URL}/home.jpg)`,
              backgroundSize: "cover",
            }}>
              <Typography sx={{ fontWeight: "bolder", fontSize: "28px", margin: "15px 0 25px" }}>{_t("Welcome to gpxplorer")}</Typography>
              <Typography>{_t("Your personnal AI roadbook for effortless rides")}</Typography>
            </Box>
            <Box sx={{
              height: "110px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: "#51AFC9",
            }}>
              <Button sx={{
                color: "white",
                background: "#FE5F55",
                display: "flex",
                width: "300px",
                marginBottom: "15px",
                '&:hover': {
                  border: "1px solid #fff",
                  transition: "0.5s ease-in-out",
                }
              }} component={Link} to='/ingest'>{_t("Start your journey →")}</Button>
              {!auth0User && <Link onClick={() => loginWithRedirect()} style={{ fontSize: "12px", color: "#fff", textDecoration: "none" }}>{_t("I already have an account")}</Link>}
            </Box>
          </Box>
        </Box>
      )}

      {user && (
        <Box>
          <Typography sx={{ fontWeight: "bolder", fontSize: "28px", margin: "15px 0 25px" }}>{_t("Your roadbooks")}</Typography>

          <Box mt={4}>
            <History />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Home;
