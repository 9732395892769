// mainly used to convert orientation to degrees
export const convertOrientationToDegrees = (orientation) => {
  const increment = 360 / 8;
  const orientations = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];

  return orientations.indexOf(orientation) * increment;
};

// returns back, front, lateral, lateral_back, lateral_front
// directions are strings: S, N, E, W, SE, SW, NE, NW
export const getWindRelativeDirection = (windDirection, roadDirection) => {
  // console.log('>>>', windDirection, roadDirection)

  windDirection = convertOrientationToDegrees(windDirection);
  roadDirection = convertOrientationToDegrees(roadDirection);

  const diff = windDirection - roadDirection;
  // console.log(windDirection, roadDirection, diff)

  if (diff === 0) return 'front';
  if (diff % 180 === 0) return 'back';
  if (diff % 90 === 0) return 'lateral';
  if (Math.abs(diff) === 45) return 'lateral_front';
  return 'lateral_back';
};

// we have 3 weather points : start, end, middle
// split the kms in 3 parts and return the nearest weather
export const getNearestWeather = (index, kms) => {
  if (index <= kms.length / 3)
    return kms[0].w;

  if (index > 2 * kms.length / 3)
    return kms[kms.length - 1].w;

  return kms[Math.round(kms.length / 2)].w;
};