const CustomCursor = props => {
  const { points } = props;

  const { x, y } = points[0];

  return (
    <g style={{ zIndex: 9 }} key={`cursor-${x}-${y}`} transform={`translate(${x},${y})`}>
      <path
        d={`M0,0L0,0M0,${400}L0,-20`}
        stroke="#51AFC9"
        strokeWidth={1}
        fill="none"
      />
    </g>
  );
};

export default CustomCursor;