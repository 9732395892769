import { Box, Button, Typography } from "@mui/material";
import Layout from "./Layout";
import _t from "../utils/i18n";
import { useState, useEffect } from "react";
import { API_BASE_URL } from "../config";
import { useDispatch, useSelector } from "react-redux";
import BikeLoader from "../Other/BikeLoader";
import { selectUser, tokenSelector } from "../selectors";
import { FileUpload } from "@mui/icons-material";

const UploadGpx = ({ userHash, onUseSamples }) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  const user = useSelector(selectUser);

  useEffect(() => {
    if (!file || !uploading) return;

    const upload = async () => {
      const formData = new FormData();
      formData.append('file', file);

      if (user)
        formData.append('user', user.id);
      else if (userHash)
        formData.append('user', userHash);

      const url = API_BASE_URL.clone().segment('upload').toString();

      try {
        const headers = {};

        const response = await fetch(url, {
          method: 'POST',
          headers,
          body: formData,
        });

        if (!response.ok) {
          setError('Upload failed');
          return;
        }

        const { roadbook } = await response.json();

        setUploading(false);
        dispatch({ type: 'roadbookHash/set', payload: roadbook.id });
        dispatch({ type: 'ingest/set',  payload: 'gpx_uploaded' });
      } catch (e) {
        setError('Upload failed');
        return;
      }
    };

    upload();
  }, [file, uploading, userHash, user?.id]);

  return (
    <Layout step={1}>
      <Box className="UploadGpx" width="100%">
        <Box className="Title" sx={{ textAlign: "left" }}>
          <Typography variant="h4">{_t("Create a roadbook for your next ride")}</Typography>
          <Typography>{_t("Submit GPX file or connect Strava to start roadbook creation.")}</Typography>
        </Box>
        <Box mt={4}>

          {error && (
            <Box>
              <p>Error: {error}</p>
            </Box>
          )}

          {!file && !uploading && (
            <>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: { xs: "150px", sm: "175px"},
                    height: { xs: "150px", sm: "175px"},
                    borderRadius: "5px",
                    color: "#fff",
                    marginRight: "10px",
                    fontSize: { xs: "12px", sm: "18px" },
                  }}
                >
                  <FileUpload sx={{ fontSize: { xs: "25px", sm: "40px" }, marginBottom: "10px" }} />
                  {_t("Import .gpx file")}
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    accept='.gpx'
                    hidden
                  />
                </Button>

                <Button
                  variant="contained"
                  component="label"
                  disabled
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: { xs: "150px", sm: "175px"},
                    height: { xs: "150px", sm: "175px"},
                    borderRadius: "5px",
                    color: "#fff",
                    marginLeft: "10px",
                    fontSize: { xs: "12px", sm: "18px" },
                  }}
                >
                  <img src="https://www.vectorlogo.zone/logos/strava/strava-ar21.svg" alt="Strava" height="30px" />
                  {_t("Import from Strava")}
                  <small>{_t("(Comming soon)")}</small>
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    accept='.gpx'
                    hidden
                  />
                </Button>
              </Box>
              <Box mt={2}>
                <Button variant="outlined" onClick={onUseSamples}>{_t("Don't have a GPX file?")}</Button>
              </Box>
            </>
          )}

          {file && !uploading && (
            <Box mt={2}>
              <Typography>{file.name}</Typography>

              <Box mt={2}>
                <Button sx={{ marginRight: "10px" }} variant="outlined" onClick={() => setFile(null)}>{_t("Cancel")}</Button>
                <Button variant="contained" sx={{ color: '#fff' }} onClick={() => setUploading(true)}>{_t("Import")}</Button>
              </Box>
            </Box>
          )}

          {uploading && (
            <Box>
              <BikeLoader />
            </Box>
          )}

        </Box>
      </Box>
    </Layout>
  )
};

export default UploadGpx;