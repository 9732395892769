import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Signup = ({ isAuthenticated, loginWithRedirect }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated)
      return navigate('/');

    loginWithRedirect({ screen_hint: 'signup' });
  }, []);

  return null;
};

export default Signup;