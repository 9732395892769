import { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import _t from '../utils/i18n';
import BikeLoader from "../Other/BikeLoader";
import Create from './Create';
import { useSelector } from 'react-redux';
import { selectRoadbookHash, selectUser, selectUserHash } from '../selectors';
import FirstTime from './FirstTime';
import UploadGpx from './UploadGpx';
import { API_BASE_URL } from '../config';
import OneMoreThing from './OneMoreThing';
import { useNavigate } from 'react-router-dom';
import { useAuthentifiedFetch } from '../utils/hooks';
import { Navigate } from "react-router-dom";
import Layout from './Layout';
import Samples from './Samples';

const Ingest = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [roadbook, setRoadbook] = useState(null);

  const user = useSelector(selectUser);
  const roadbookHash = useSelector(selectRoadbookHash);
  const userHash = useSelector(selectUserHash);
  const [useSamples, setUseSamples] = useState(false);

  const haveUser = Boolean(user || userHash);

  const authentifiedFetch = useAuthentifiedFetch();

  const onAction = async (action, force = false, callback = () => {}) => {
    setLoading(true);

    const url = API_BASE_URL
      .clone()
      .segment('roadbooks')
      .segment(roadbook.id)
      .segment('process')
      .toString();

    try {
      const response = await authentifiedFetch(url, {
        method: 'POST',
        body: JSON.stringify({ action, force }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        setLoading(false);
        return false;
      }

      const data = await response.json();

      setRoadbook(data.roadbook);
      callback();
    } catch (e) {
      console.log('error', e);
      return false;
    }

    setLoading(false);
    return true;
  };

  const onCreate = async (data) => {
    // if not authenticated, goto oneMoreThing
    if (!user) {
      console.log('no user, goto onemorething')
      setRoadbook(data);
      return;
    }

    // else, trigger convert + analyze before redirecting to roadbook
    console.log('we have user, re-convert then analyze');
    await onAction('convert', false, async () => {
      console.log('converted, now analyze and redirect');
      onAction('analyze', false);

      console.log('redirect in 1sec')
      setTimeout(() => navigate(`/roadbooks/${data.id}`), 1000);
    });
  };

  useEffect(() => {
    if (roadbook) return;
    if (!roadbookHash) return;

    setLoading(true);

    const fetchRoadbook = async (hash) => {
      const url = API_BASE_URL
        .clone()
        .segment('roadbooks')
        .segment(hash)
        .toString();

      try {
        const response = await fetch(url);

        if (!response.ok)
          return;

        const { roadbook } = await response.json();

        setRoadbook(roadbook);
      } catch (e) {
        return;
      }

      setLoading(false);
    };

    fetchRoadbook(roadbookHash);
  }, [roadbookHash, roadbook]);

  if (loading)
    return (
      <Layout>
        <BikeLoader />
      </Layout>
    );

  if (!haveUser) return <FirstTime />;
  if (useSamples) return <Samples userHash={userHash} onUseSamples={() => setUseSamples(false)} />;
  if (!roadbookHash) return <UploadGpx userHash={userHash} onUseSamples={() => setUseSamples(true)} />;
  if (!roadbook?.name) return <Create roadbook={roadbook} onCreate={onCreate} />;
  if (roadbook?.name && !user) return <OneMoreThing roadbook={roadbook} />;

  if (roadbook?.name && user)
    return <Navigate to={`/roadbooks/${roadbook.id}`} />;

  return (
    <Box sx={{ padding: "20px" }}>
      <BikeLoader />
    </Box>
  );
}

export default Ingest;
