import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../config';

import { LAST_JSON_VERSION } from '../Roadbook/Roadbook';
import { useAuthentifiedFetch } from '../utils/hooks';

const Roadbooks = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [roadbooks, setRoadbooks] = useState([]);

  const fetchRoadbooks = useAuthentifiedFetch();

  useEffect(() => {
    const retrieve = async () => {
      const url = API_BASE_URL
      .clone()
      .segment('root')
      .segment('roadbooks')
      .toString();

      try {
        const response = await fetchRoadbooks(url);

        if (!response.ok)
          throw new Error;

        const { roadbooks } = await response.json();

        setRoadbooks(roadbooks);
      } catch (e) {
        setError('Retrieving roadbooks failed');
        return;
      }

      setLoading(false);
    };

    retrieve();
  }, []);

  if (loading)
    return <Box>Loading...</Box>;

  return (
    <Box>
      <Link to={`/root`} style={{ float: "left", fontSize: "16px" }}>{`< Retour`}</Link>

      <h3>All generated Roadbooks</h3>
      <ul>
        {roadbooks.map((roadbook) => (
          <li key={roadbook.id}>
            <a target="_blank" href={`/roadbooks/${roadbook.id}`}>
              {roadbook.name || roadbook.id}
              {roadbook.parsed_json && (
                <span>
                  ({roadbook.parsed_json.distance}km, {roadbook.parsed_json.elevation?.pos}m D+)
                  {roadbook.json_version !== LAST_JSON_VERSION && ' ⚠️ outdated ⚠️'}
                </span>
              )}
            </a>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default Roadbooks;