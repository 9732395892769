import _t from '../utils/i18n';
import dayjs from 'dayjs';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from '../config';

import BikeLoader from '../Other/BikeLoader';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import General from './General';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoadbookHash } from '../selectors';
import { Directions, MoreVert, Terrain, WaterDrop } from '@mui/icons-material';
import { Modal } from '@mui/material';
import Create from '../Ingest/Create';
import { useAuthentifiedFetch } from '../utils/hooks';
import History from '../History';

export const LAST_JSON_VERSION = "1.3.0";

const a11yProps = index => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Roadbook = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const [roadbook, setRoadbook] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const roadbookHash = useSelector(selectRoadbookHash);
    const authentifiedFetch = useAuthentifiedFetch();

    const weather = roadbook?.parsed_json?.kms[0]?.w;

    useEffect(() => {
      if (!roadbookHash || !roadbook) return;
      dispatch({ type: 'ingest/unsetAll' });
    }, [roadbookHash, roadbook]);

    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };

    const onAction = async (action, force = false, callback = () => {}) => {
      setLoading(true);

      const url = API_BASE_URL
        .clone()
        .segment('roadbooks')
        .segment(params.id)
        .segment('process')
        .toString();

      try {
        const response = await authentifiedFetch(url, {
          method: 'POST',
          body: JSON.stringify({ action, force }),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          setError(`Action ${action} failed`);
          setLoading(false);
          return false;
        }

        const data = await response.json();

        setRoadbook(data.roadbook);
        callback();
      } catch (e) {
        setError(`Action ${action} failed`);
        return false;
      }

      setLoading(false);
      return true;
    };

    const retrieve = async () => {
      const url = API_BASE_URL
        .clone()
        .segment('roadbooks')
        .segment(params.id)
        .toString();

      try {
        const response = await fetch(url);
        const data = await response.json();

        setRoadbook(data.roadbook);
      } catch (e) {
        setError('Retrieving roadbook failed');
        return;
      }
    };

    // retrieve once
    useEffect(() => {
      retrieve();
    }, [params.id]);

    // retrieve periodically if ongoing_process
    useEffect(() => {
      if (!roadbook) return;

      if (roadbook.json_version !== LAST_JSON_VERSION) {
        onAction('convert');
        return;
      }

      const interval = setInterval(() => {
        if (!roadbook.ongoing_process) return;

        const pocesses = Object.keys(roadbook.ongoing_process).filter(key => roadbook.ongoing_process[key]);

        if (!pocesses.length) return;

        retrieve();
      }, 5000);

      return () => clearInterval(interval);
    }, [roadbook]);

    if (!roadbook)
      return (
        <Box sx={{ height: "calc(100vh - 150px)", display: "flex", alignItems: "center", justifyContent: "center", }}>
          <BikeLoader />
        </Box>
      );

    if (roadbook?.json_version !== LAST_JSON_VERSION) {
      return (
        <Box sx={{ height: "calc(100vh - 150px)", display: "flex", alignItems: "center", justifyContent: "center", }}>
          {_t("This roadbook is not up to date. Wait a few seconds while we update it. Thanks")}
        </Box>
      );
    }

    return (
      <Box className="Wrapper" sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        position: "relative",
        marginTop: { xs: "10px", md: "45px" },
        width: "90vw",
        maxWidth: { xs: "85vw", md: "1200px" },
        marginBottom: "50px",
      }}>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "100%",
            maxWidth: { xs: "80vw", md: "400px" },
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            p: 4,
          }}>
            <Typography variant="h6" component="h2">
              {_t("Edit roadbook parameters")}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <Create roadbook={roadbook} onCreate={(editedRoadbook) => {
                setRoadbook(editedRoadbook);
                setOpen(false);

                // re-convert than re-analyze
                onAction('convert', false, () => onAction('analyze', true));
              }} editMode />
            </Typography>
          </Box>
        </Modal>

        <Box className="Left" sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          display: { xs: "none", md: "flex" },
          width: "300px",
          marginTop: "-32px",
        }}>
          <History />
        </Box>

        <Box className="Right" sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: { xs: "100%", md: "calc(100% - 300px)" },
          background: "#ffffff",
          padding: "15px",
        }}>
          <Box display="flex" width="100%" sx={{ flexDirection: { xs: "column", md: "row" }}}>

            <Box className="Header" display="flex" flexDirection="column" mb="15px" sx={{ flex: '1 auto' }}>
              <Box className="Title" display="flex" flex="1 auto">
                {!roadbook.name && _t("Unnamed roadbook")}
                {roadbook.name && <Box sx={{ fontWeight: "bold", fontSize: "18px" }}>{roadbook.name}</Box>}
              </Box>

              <Box>
                <Box className="Date" display="flex">
                  {roadbook.ride_date && <Box mt={1} sx={{ fontWeight: "bold", color: "gray", fontSize: "14px" }}>{dayjs(roadbook.ride_date).format("MMMM D, YYYY")}</Box>}
                  {!roadbook.ride_date && _t("No ride date")}

                  {weather && (
                    <Box>
                      <img width="30" height="30" src={`https://openweathermap.org/img/wn/${weather.icon}@2x.png`} />
                    </Box>
                  )}

                </Box>
              </Box>
            </Box>

            <Box sx={{ marginTop: { md: "-30px" }}} className="Recap" display="flex" flex="none" alignItems="center">
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", color: "darkgrey" }}>
                <Directions />{roadbook.distance}km
              </Box>
              <Box ml={2} sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", color: "darkgrey" }}>
                <Terrain />{roadbook.elevation}m D+
              </Box>
              <Box ml={2} sx={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "flex-start", color: "darkgrey" }}>
                <MoreVert onClick={() => setOpen(true)} />
              </Box>
            </Box>

          </Box>

          <Box className="Content" display="flex" flexDirection="column" width="100%">

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs variant="scrollable" value={value} onChange={handleTabChange} aria-label="roadbook tabs">
                <Tab label={_t("General")} {...a11yProps('general')} />
                <Tab label={_t("Nutrition")} {...a11yProps('nutrition')} />
                <Tab label={_t("Climbs")} {...a11yProps('climbs')} />
                <Tab label={_t("Weather")} {...a11yProps('weather')} />
                <Tab label={_t("Packing")} {...a11yProps('packing')} />
                <Tab label={_t("POI")} {...a11yProps('poi')} />
                <Tab label={_t("Export")} {...a11yProps('export')} />
              </Tabs>
            </Box>
            <CustomTabPanel hidden={value !== 0}>
              <General roadbook={roadbook} onAction={onAction} />
            </CustomTabPanel>
            <CustomTabPanel hidden={value !== 1}>
              {_t("Upcoming..")}
              {/* <Nutrition roadbook={roadbook} onAction={onAction} /> */}
            </CustomTabPanel>
            <CustomTabPanel hidden={value !== 2}>
              {_t("Upcoming..")}
            </CustomTabPanel>
            <CustomTabPanel hidden={value !== 3}>
              {_t("Upcoming..")}
            </CustomTabPanel>
            <CustomTabPanel hidden={value !== 4}>
              {_t("Upcoming..")}
              {/* <Packing roadbook={roadbook} onAction={onAction} /> */}
            </CustomTabPanel>
            <CustomTabPanel hidden={value !== 5}>
              {_t("Upcoming..")}
              {/* <POI roadbook={roadbook} onAction={onAction} /> */}
            </CustomTabPanel>
            <CustomTabPanel hidden={value !== 6}>
              {_t("Upcoming..")}
            </CustomTabPanel>
          </Box>
        </Box>

      </Box>
    )

  }

  export default Roadbook;
