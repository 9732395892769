import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuthentifiedFetch } from '../utils/hooks';

const CustomTabPanel = ({ value, index, settingKey, settingValue, onUpdate }) => {
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);

  const fetchPrompts = useAuthentifiedFetch();

  const onSave = async (key, value) => {
    setUpdating(true);

    const url = API_BASE_URL
      .clone()
      .segment('root')
      .segment('settings')
      .toString();

    try {
      const response = await fetchPrompts(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ key, value }),
      });

      if (!response.ok) {
        setError('Update failed');
        setUpdating(false);
        return;
      }

      const data = await response.json();

      setUpdating(false);
    } catch (e) {
      setError('Update failed');
      setUpdating(false);
      return;
    }
  };

  if (value !== index) return null;

  return (
    <Box>
      <Box sx={{ p: 3 }}>

        <Box sx={{ textAlign: "right", fontSize: "12px", marginBottom: "5px" }}>{settingValue.length} caractères</Box>
        <textarea
          placeholder={`Enter the initial prompt for the ${settingKey} configuration.`}
          style={{ width: "100%", height: "200px", padding: "15px" }}
          onChange={(e) => onUpdate(settingKey, e.target.value)}
          defaultValue={settingValue}
        />
        <Button sx={{ marginTop: "10px" }} variant="contained" disabled={updating} onClick={() => onSave(settingKey, settingValue)}>{updating ? 'Updating...' : 'Update'}</Button>

      </Box>
    </Box>
  );
}

const Prompts = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [prompts, setPrompts] = useState({
    prompt_general_flat_fr: '',
    prompt_general_medium_fr: '',
    prompt_general_hard_fr: '',
  });

  const onUpdate = (key, value) => setPrompts({ ...prompts, [key]: value });

  const fetchPrompts = useAuthentifiedFetch();

  useEffect(() => {
    const retrieve = async () => {
      const url = API_BASE_URL
      .clone()
      .segment('root')
      .segment('settings')
      .toString();

      try {
        const response = await fetchPrompts(url);

        if (!response.ok)
          throw new Error;

        const data = await response.json();

        setPrompts({ ...prompts, ...data });
      } catch (e) {
        setError('Retrieving settings failed');
        return;
      }

      setLoading(false);
    };

    retrieve();
  }, []);

  if (loading)
    return <Box>Loading...</Box>;

  return (
    <Box sx={{ width: '80vw', maxWidth: '800px', margin: "25px auto" }}>

      <Link to={`/root`} style={{ float: "left", fontSize: "16px" }}>{`< Retour`}</Link>

      <Typography variant="h4" component="h1" gutterBottom>Prompt Général</Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label="Profile Flat" />
          <Tab label="Profile Medium" />
          <Tab label="Profile Hard" />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0} settingKey={`prompt_general_flat_fr`} settingValue={prompts.prompt_general_flat_fr} onUpdate={onUpdate} />
      <CustomTabPanel value={value} index={1} settingKey={`prompt_general_medium_fr`} settingValue={prompts.prompt_general_medium_fr} onUpdate={onUpdate} />
      <CustomTabPanel value={value} index={2} settingKey={`prompt_general_hard_fr`} settingValue={prompts.prompt_general_hard_fr} onUpdate={onUpdate} />

      <Box mt={4}>
        <strong>Variables disponibles :</strong>
        <ul style={{ listStyle: 'none', textAlign: 'left' }}>
          <li><strong>{`{{ user.gender }}`}</strong>: Une de ces valeurs là {`['male', 'female', 'other']`}</li>
          <li><strong>{`{{ user.birthdate }}`}</strong> : date de naissance du cycliste (format jj/mm/yyyy pour l'instant)</li>
          <li><strong>{`{{ user.cycling_motivation }}`}</strong>: Une de ces valeurs là {`['fun', 'competition', 'exploration', 'pushing', 'other']`}</li>
          <li><strong>{`{{ roadbook.ride_date }}`}</strong>: date prévue de l'activité (format jj/mm/yyyy pour l'instant)</li>
          <li><strong>{`{{ roadbook.current_weight }}`}</strong>: Poids du cycliste au moment de l'activité</li>
          <li><strong>{`{{ roadbook.current_fitness }}`}</strong>: Fitness du cycliste au moment de l'activité, une des valeurs {`['very_low', 'low', 'medium', 'high', 'very_high']`}</li>
        </ul>
      </Box>
    </Box>
  );
};

export default Prompts;