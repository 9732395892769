import _t from '../../utils/i18n';
import Box from '@mui/material/Box';
import { getNearestWeather, getWindRelativeDirection } from './utils';

const Tooltip = ({ active, payload, label, kms }) => {
  if (!active || !payload || !payload.length) return null;

  const nearestWeather = getNearestWeather(Math.round(label), kms);
  let windDirection = nearestWeather ? getWindRelativeDirection(nearestWeather.wind_direction, kms[Math.round(label)]?.o) : false;

  switch (windDirection) {
    case 'lateral': windDirection = _t('Lateral'); break;
    case 'lateral_front': windDirection = _t('Crosswind'); break;
    case 'lateral_back': windDirection = _t('Cross-back'); break;
    case 'front': windDirection = _t('Headwind'); break;
    case 'back': windDirection = _t('Backwind'); break;
  }

  return (
    <Box sx={{ background: "#51AFC9", color: "#ffffff", borderRadius: "5px", padding: "5px 10px", boxShadow: "0 1px 2px rgba(0,0,0,.1)" }}>
      <Box sx={{ fontSize: "12px"}}>{_t("Distance:")} {Math.round(label)} km</Box>
      <Box sx={{ fontSize: "12px"}}>{_t("Elevation:")} {payload[0].value} m</Box>

      {/* {kms[Math.round(label)]?.o && <Box sx={{ fontSize: "12px"}}>{_t("Direction:")} {kms[Math.round(label)].o}</Box>}
      {nearestWeather && (
        <Box sx={{ fontSize: "12px"}}>{_t("Wind direction:")} {nearestWeather.wind_direction}</Box>
      )} */}

      {windDirection && (
        <>
          <Box sx={{ fontSize: "12px"}}>{_t("Wind direction:")} {windDirection}</Box>
          <Box sx={{ fontSize: "12px"}}>{_t("Wind speed:")} {Math.round(nearestWeather.wind * 3600 / 1000)}km/h</Box>
        </>
      )}

    </Box>
  );
};

export default Tooltip;