import { Box, Button, Typography } from "@mui/material";
import Layout from "./Layout";
import _t from "../utils/i18n";
import BikeLoader from "../Other/BikeLoader";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectUserHash, tokenSelector } from "../selectors";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../config";
import { useNavigate } from "react-router-dom";
import basil from "../utils/basil";
import { useProcessRoadbook } from "../utils/hooks";

let once = false;

const OneMoreThing = ({ roadbook }) => {
  const {
    loginWithRedirect,
  } = useAuth0();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onProcess = useProcessRoadbook(roadbook);

  const user = useSelector(selectUser);
  const userHash = useSelector(selectUserHash);
  const access_token = useSelector(tokenSelector);

  const onLink = async (hash) => {
    const url = API_BASE_URL
      .clone()
      .segment('users')
      .segment(hash)
      .segment('link')
      .toString();

    try {
      const response = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
      });

      if (!response.ok) {
        console.log('link failed');
        const error = await response.json();

        // TODO: do something here. Maybe re-change roadbook ownership ?
        if (error.error === 'User already linked.') {
          alert(_t("This account already exist. Please, re-submit your gpx file to link it to this account."));
          dispatch({ type: 'ingest/unsetAll' });
        }

        return;
      }

      const { user } = await response.json();

      dispatch({ type: 'user/set', payload: user });
    } catch (e) {
      console.log('link failed', e);
      return;
    }

    // clear storages !
    dispatch({ type: 'ingest/unsetAll' });
    dispatch({ type: 'ingest/end' });

    navigate(`/roadbooks/${roadbook.id}`);
  };

  useEffect(() => {
    if (user) return;
    if (!access_token || !userHash) return;
    if (once) return;

    once = true;

    // now that the user created an account, link it to the user in the DB
    onLink(userHash);
  }, [user, userHash, access_token]);

  useEffect(() => {
    basil.set('redirect', '/ingest');

    if (!user)
      onProcess('convert');

    if (roadbook && user)
      navigate(`/roadbooks/${roadbook.id}`);
  }, []);

  const onCancel = () => {
    dispatch({ type: 'ingest/unsetAll' });
    setTimeout(() => navigate('/'), 100);
  };

  return (
    <Layout step={3}>
      <Box width="100%">
        <Box className="Title" sx={{ textAlign: "left" }}>
          <Typography variant="h4">{_t("Crafting your personalized roadbook")}</Typography>
        </Box>
        <Box mt={8}>
          <BikeLoader />
        </Box>
        <Box mt={4}>
          <Typography sx={{ fontWeight: "bold" }}>{_t("To access and save your custom-crafted guide, let's quickly set up your account. It's fast and painless, we promise!")}</Typography>
        </Box>
        <Box mt={8} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <Button sx={{
            color: "white",
            background: "#FE5F55",
            display: "flex",
            width: "300px",
            marginBottom: "15px",
            '&:hover': {
              background: "#51AFC9",
              transition: "0.5s ease-in-out",
            }
          }} onClick={() => loginWithRedirect({
            redirectTo: window.location.href,
            authorizationParams: {
              redirectTo: window.location.href,
              screen_hint: 'signup',
            }
          })}>{_t("Sign in →")}</Button>
          <Box onClick={onCancel} sx={{ display: "flex", fontSize: "10px", cursor: "pointer" }}>{_t("Or cancel")}</Box>
        </Box>
      </Box>
    </Layout>
  )
};

export default OneMoreThing;