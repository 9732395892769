import _t from '../../utils/i18n';

import Box from '@mui/material/Box';
import { Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Rectangle } from 'recharts';

import useMediaQuery from '@mui/material/useMediaQuery';
import { ScreenRotation } from '@mui/icons-material';

import CustomTooltip from './Tooltip';
import CustomizedTick from './CustomizedTick';
import CustomizedYTick from './CustomizedYTick';
import CustomCursor from './CustomCursor';

const Profile = ({ roadbook }) => {
  const isMobilePortrait = useMediaQuery('@media screen and (max-width: 400px) and (orientation:portrait)');
  const isMobileLandscape = useMediaQuery('@media screen and (max-width: 900px) and (orientation:landscape)');

  const { track_points, parsed_json } = roadbook;
  const { kms } = parsed_json;

  const data = [];

  // ensure there are no double values for same distance
  track_points.points.forEach(({ d, e }) => {
    if (data.length && data[data.length - 1][_t('Distance (km)')] === Math.round(d / 100) / 10) return;
    data.push({ [_t('Distance (km)')]: Math.round(d / 100) / 10, [_t("Elevation (m)")]: e });
  });

  // ensure ascents are not too close (depending on total distance)
  // if two ascents are too close, discard the one with less points
  const ascents = (roadbook.parsed_json?.ascents || []).sort((a, b) => a.start_km - b.start_km);
  const ascentsToRemove = [];

  // approx 3km on a 120km ride. Depend on ticks number and 600px wide. Adjust if necessary
  const ticks = roadbook.parsed_json.profile === 'hard' ?  50 : 50;
  const limit = ticks / (track_points.points.length / parsed_json.distance);

  for (let i = 0; i < ascents.length - 1; i++) {
    const ascent = ascents[i];
    const nextAscent = ascents[i + 1];

    // console.log(nextAscent.start_km, ascent.end_km, nextAscent.start_km - ascent.end_km, limit)

    if (nextAscent.start_km - ascent.end_km < limit) {
      console.log('>> removed ascent');
      ascentsToRemove.push(ascent.points < nextAscent.points ? ascent : nextAscent);
    }
  }

  // remove ascents
  ascentsToRemove.forEach(ascent => ascents.splice(ascents.indexOf(ascent), 1));

  // ensure that ascents start and end matches a tick
  // if not, add a tick
  (roadbook.parsed_json?.ascents || []).forEach(ascent => {
    if (data.find(d => d[_t('Distance (km)')] !== ascent.start_km))
      data.push({ [_t('Distance (km)')]: ascent.start_km, [_t("Elevation (m)")]: ascent.start_alt });

    if (data.find(d => d[_t('Distance (km)')] !== ascent.end_km))
      data.push({ [_t('Distance (km)')]: ascent.end_km, [_t("Elevation (m)")]: ascent.end_alt });
  });

  // ensure that every km matches a tick
  // if not, add a tick
  (roadbook.parsed_json?.kms || []).forEach(km => {
    data.push({ [_t('Distance (km)')]: km.km });
  });

  // now re-sort data depending on distance
  data.sort((a, b) => a[_t('Distance (km)')] - b[_t('Distance (km)')]);

  // extract ascents start and end alt
  const ascentsAlt = (roadbook.parsed_json?.ascents || []).reduce((acc, ascent) => {
    acc.push(Math.round(ascent.start_alt));
    acc.push(Math.round(ascent.end_alt));
    return acc;
  }, [0]);

  // sort ascents alt
  ascentsAlt.sort((a, b) => a - b);

  // if alt max < 1000m, add 1000m
  if (ascentsAlt[ascentsAlt.length - 1] < 1000) ascentsAlt.push(1000);

  return (
    <Box className={`Profile Profile--${isMobileLandscape ? 'mobileLandscape' : 'normal'}`} height={isMobilePortrait ? 200 : 500} position="relative">
      {isMobilePortrait && (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(0, 0, 0, .8)',
          zIndex: 999,
        }}>
          <ScreenRotation sx={{ fontSize: 50 }} />
        </Box>
      )}

      <ResponsiveContainer>
        <ComposedChart
          width={"100%"}
          data={data}
          margin={{
            top: isMobilePortrait ? 50 : 200,
            right: 30,
            left: 0,
            bottom: isMobilePortrait ? 20 : 55,
          }}
        >
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#c96b51" stopOpacity={0.8}/>
              <stop offset="90%" stopColor="#51AFC9" stopOpacity={0.7}/>
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="5 5" vertical={false} />
          <XAxis
            dataKey={_t('Distance (km)')}
            interval={1}
            tick={<CustomizedTick ascents={roadbook.parsed_json?.ascents || []} kms={kms} />}
            tickSize={0}
          />
          <YAxis
            type="number"
            interval={"preserveEnd"}
            ticks={ascentsAlt}
            tickCount={ascentsAlt.length}
            tick={<CustomizedYTick alts={ascentsAlt} />}
            domain={[0, ascentsAlt[ascentsAlt.length - 1]]}
          />
          <Tooltip cursor={<CustomCursor />} content={<CustomTooltip kms={kms} />}  />
          {/* <Legend verticalAlign="top" height={36} /> */}
          <Area connectNulls name={_t("Elevation (m)")} type="monotone" dataKey={_t("Elevation (m)")} stroke="#51AFC9" fill="url(#gradient)" />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default Profile;