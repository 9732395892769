import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import BikeLoader from "./Other/BikeLoader";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL, AUTH0_DOMAIN, AUTH0_CUSTOM_DOMAIN } from "./config";
import { tokenSelector } from "./selectors";
import { useAuthentifiedFetch } from "./utils/hooks";

const AuthWrapper = ({ children }) => {
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const dispatch = useDispatch();

  const [userMetadata, setUserMetadata] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [authDone, setAuthDone] = useState(false);

  const authentifiedFetch = useAuthentifiedFetch();
  const access_token = useSelector(tokenSelector);

  useEffect(() => {
    if (isLoading) return;

    // if Auth0 done loading but no user, we're done, we don't need to fetch user metadata
    if (!user) {
      console.log('anonymous user');
      setAuthDone(true);
      return;
    }

    const getUserMetadata = async () => {
      console.log('fetching user metadata');

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${AUTH0_DOMAIN}/api/v2/`,
            scope: "read:current_user",
          },
        });

        const userDetailsByIdUrl = `https://${AUTH0_CUSTOM_DOMAIN}/api/v2/users/${user.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { user_metadata, ...userInfo } = await metadataResponse.json();

        setUserInfo(userInfo)
        setUserMetadata(user_metadata);

        dispatch({ type: 'access_token/set', payload: accessToken });
        dispatch({ type: 'user/auth0/set', payload: user.sub });
        dispatch({ type: 'userInfo/set', payload: userInfo });
        dispatch({ type: 'userMetadata/set', payload: user_metadata });
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub, isLoading]);

  useEffect(() => {
    if (!access_token) return;

    const bootstrap = async () => {
      console.log('fetching user');
      const url = API_BASE_URL
        .clone()
        .segment('bootstrap')
        .toString();

      try {
        const response = await authentifiedFetch(url);

        if (!response.ok)
          throw new Error;

        const { user } = await response.json();

        dispatch({ type: 'user/set', payload: user });
      } catch (e) {
        console.log('bootstrap failed', e);
      }

      setAuthDone(true);
    };

    bootstrap();
  }, [access_token]);

  if (!authDone)
    return (
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}>
        <BikeLoader />
      </Box>
    );

  console.log('rendering component')

  return <children.type
    {...children.props}
    isLoading={isLoading}
    user={user}
    isAuthenticated={isAuthenticated}
    loginWithRedirect={loginWithRedirect}
    logout={logout}
    userMetadata={userMetadata}
    userInfo={userInfo}
  />
};

export default AuthWrapper;