import { Box, Button, Typography } from "@mui/material";
import _t from "../utils/i18n";
import { InsertEmoticon, Send, SentimentSatisfied, SentimentVeryDissatisfied } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useEditRoadbook } from "../utils/hooks";

const Survey = ({ roadbook }) => {
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState('');
  const [thankYou, setThankYou] = useState(false);

  const onEditRoadbook = useEditRoadbook(roadbook);

  const onRate = (value) => {
    setRating(value);
  };

  const onSend = async () => {
    if (rating === null) return;

    const hasComments = Boolean(comment.trim().length);

    const data = {
      satisfaction_score: rating,
    };

    if (hasComments)
      data.satisfaction_comment = comment;

    await onEditRoadbook(data, {
      onSuccess: () => hasComments ? setThankYou(true) : null,
    });
  };

  useEffect(() => {
    onSend();
  }, [rating]);

  useEffect(() => {
    if (!thankYou) return;
    setTimeout(() => alert(_t("Thank you for your feedback!")), 200);
  }, [thankYou]);

  if (thankYou)
    return null;

  return (
    <Box sx={{
      padding: "3px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      background: "#fcfcfc",
      border: "1px solid #ccc",
      transition: "all 0.3s ease",
    }}>
      <Box sx={{
        position: "absolute",
        fontSize: "10px",
        fontWeight: "bold",
        color: "darkgray",
        top: -8,
        left: 3,
      }}
      >{_t("Honnest feedback ;)")}</Box>

      <Box display="flex" flexDirection="column" position="relative">
        <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ color: "#ccc" }}>
          <Box onClick={() => onRate(rating === -1 ? null : -1)} sx={{ cursor: "pointer", height: "40px", "&:hover": { color: "red" }, color: rating === -1 ? 'red' : 'inherit' }}>
            <SentimentVeryDissatisfied style={{ fontSize: "40px", "&:hover": { color: "red" }}} />
          </Box>

          <Box onClick={() => onRate(rating === 0 ? null : 0)}  sx={{ cursor: "pointer", height: "40px", "&:hover": { color: "orange" }, color: rating === 0 ? 'orange' : 'inherit' }}>
            <SentimentSatisfied style={{ fontSize: "40px" }} />
          </Box>

          <Box onClick={() => onRate(rating === 1 ? null : 1)}  sx={{ cursor: "pointer", height: "40px", "&:hover": { color: "green" }, color: rating === 1 ? 'green' : 'inherit' }}>
            <InsertEmoticon style={{ fontSize: "40px" }} />
          </Box>
        </Box>

        {rating !== null && (
          <Box>
            <textarea
              autoFocus
              placeholder={_t("Please tell us why")}
              style={{ marginTop: "3px", width: "270px", height: "50px", padding: "10px" }}
              onChange={(e) => setComment(e.target.value)}
              defaultValue={comment}
            />

            <Box sx={{ textAlign: "right" }}>
              <Box onClick={onSend} sx={{ cursor: "pointer", color: "#51AFC9" }}>
                <Send />
              </Box>
            </Box>
          </Box>
        )}

      </Box>

    </Box>
  )
};

export default Survey;