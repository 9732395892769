import _t from "../../utils/i18n";

const CustomizedYTick = ({ x, y, payload, index, alts }) => {
  const { value } = payload;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-10} y={-14} dy={16} className="small" textAnchor="end" fill="#666">
        {value}
      </text>

      {value === alts[alts.length - 1] && (
        <text x={-20} y={-30} dy={16} textAnchor="middle" fill="#666" className="small">
          {_t("Altitude (m)")}
        </text>
      )}
    </g>
  );
};

export default CustomizedYTick;