import _t from "../utils/i18n";

import { useEffect, useState } from 'react';
import { Box, Button } from "@mui/material";

import Profile from './Profile/Profile';
import BikeLoader from "../Other/BikeLoader";

import Survey from "./Survey";
import { selectUser, selectUserMetadata } from "../selectors";
import { useSelector } from "react-redux";

let timeout = null;
const FORCE_REFRESH_DELAY = 30 * 1000;

const General = ({ roadbook, onAction }) => {
  const [loading, setLoading] = useState(false);
  const [allowForce, setAllowForce] = useState(false);

  const user = useSelector(selectUser);
  const userMetadata = useSelector(selectUserMetadata);

  const onRefresh = async (force = false) => {
    console.log('refreshing analyze')
    setLoading(true);
    await onAction('analyze', force);
    setLoading(false);
  };

  useEffect(() => {
    if (!roadbook) return;
    if (roadbook.summary) return;
    if (roadbook.ongoing_process?.analyze) return;

    onRefresh();
  }, [roadbook]);

  useEffect(() => {
    if (!roadbook.summary || loading) {
      timeout = setTimeout(() => setAllowForce(true), FORCE_REFRESH_DELAY);
      return;
    }

    clearTimeout(timeout);
  }, [roadbook.summary, loading]);

  return (
    <Box className="General" width="100%" padding="0">
      {roadbook.track_points && <Profile key={roadbook.id} roadbook={roadbook} />}

      <Box className="WTE" mt={4} sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        background: "#f9f6f1",
        padding: "15px 25px",
      }}>
        <Box mb={3} sx={{ fontWeight: "bold" }}>{_t("What to expect")}</Box>

        {(!roadbook.summary || loading) && (
          <Box width="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <BikeLoader />

            {allowForce && userMetadata?.is_root && (
              <Box mt={2}>
                <Button onClick={() => onRefresh(true)} size="small" variant="outlined" color="primary">{_t("Force refresh")}</Button>
              </Box>
            )}
          </Box>
        )}

        {roadbook.summary && !loading && (
          <>
            {roadbook.ongoing_process.analyze && (
              <Box mb={2} sx={{ fontStyle: 'italic' }}>{_t("This roadbook is curently re-generating due to parameters changes...")}</Box>
            )}

            <Box
              sx={{ textAlign: "left" }}
              dangerouslySetInnerHTML={{ __html: roadbook.summary.replace(/(?:\r\n|\r|\n)/g, '<br />') }}
            />

            {user?.id === roadbook.user_id && !roadbook.satisfaction_score && (
              <Box display="flex" justifyContent="flex-end" width="100%">
                <Survey roadbook={roadbook} />
              </Box>
            )}

            {userMetadata?.is_root && (
              <Box mt={2} display="flex" justifyContent="flex-end" width="100%">
                <Button onClick={onRefresh} size="small" variant="outlined" color="primary">{_t("Regenerate")}</Button>
              </Box>
            )}
          </>
        )}
        </Box>
    </Box>
  )
};

export default General;