import { useSelector } from "react-redux";
import { API_BASE_URL } from "../config";
import { tokenSelector } from "../selectors";

export const useProcessRoadbook = (roadbook) => {
  const authentifiedFetch = useAuthentifiedFetch();

  const onProcess = async (action, { setLoading = () => {}, onSuccess = () => {}, onError = () => {} } = {}) => {
    setLoading(true);

    const url = API_BASE_URL
      .clone()
      .segment('roadbooks')
      .segment(roadbook.id)
      .segment('process')
      .toString();

    try {
        const response = await authentifiedFetch(url, {
          method: 'POST',
          body: JSON.stringify({ action }),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok)
          throw new Error;

        const data = await response.json();

        onSuccess(data.roadbook);
    } catch (e) {
      onError(`Action ${action} failed`);
    }

    setLoading(false);
  };

  return onProcess;
};

export const useAuthentifiedFetch = (options = {}) => {
  const access_token = useSelector(tokenSelector);

  const headers = {
    'Content-Type': 'application/json',
    ...options.headers,
  };

  if (access_token)
    headers.Authorization = `Bearer ${access_token}`;

  const authentifiedFetch = async (url, options = {}) => {
    const response = await fetch(url, {
      ...options,
      headers,
    });

    return response;
  };

  return authentifiedFetch;
};

export const useEditRoadbook = (roadbook) => {
  const authentifiedFetch = useAuthentifiedFetch();

  const onEdit = async (data, { setLoading = () => {}, onSuccess = () => {}, onError = () => {} } = {}) => {
    setLoading(true);

    console.log('on edit', data)

    const url = API_BASE_URL
      .clone()
      .segment('roadbooks')
      .segment(roadbook.id)
      .toString();

    try {
      console.log('ici')
        const response = await authentifiedFetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok)
          throw new Error;

        const { roadbook } = await response.json();

        onSuccess(roadbook);
    } catch (e) {
      console.log('err', e)
      onError(e);
    }

    setLoading(false);
  };

  return onEdit;
}