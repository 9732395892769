import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import Layout from "./Layout";
import _t from "../utils/i18n";
import { ChevronLeft } from "@mui/icons-material";
import { useState } from "react";
import { useAuthentifiedFetch } from "../utils/hooks";
import { API_BASE_URL } from "../config";
import { useDispatch } from "react-redux";

const Samples = ({ userHash, onUseSamples }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('edt');

  const authentifiedFetch = useAuthentifiedFetch({
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // },
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  const onImport = async () => {
    const url = API_BASE_URL.clone().segment('upload').toString();

    const data = { sample: value };

    if (userHash)
      data.user = userHash;

    const response = await authentifiedFetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      alert('Upload failed');
      return;
    }

    const { roadbook } = await response.json();

    dispatch({ type: 'roadbookHash/set', payload: roadbook.id });
    dispatch({ type: 'ingest/set',  payload: 'gpx_uploaded' });

    onUseSamples();
  };

  return (
    <Layout step={4}>
      <Box width="100%">
        <Box className="Title" sx={{ textAlign: "left", cursor: "pointer" }} onClick={onUseSamples}>
          <Typography variant="h4"><ChevronLeft /> {_t("No .gpx file? No problem!")}</Typography>
          <Typography>{_t("Explore our collection and select a route that intrigues you. Whether you're in the mood for mountainous terrains or coastal cruises, there's something for everyone.")}</Typography>
        </Box>
        <Box mt={4}>

          <Box onClick={() => setValue('edt')} display="flex" sx={{ cursor: "pointer", border: "1px solid #51AFC9", marginBottom: "-1px", background: value === 'edt' ? 'rgba(81, 175, 201, 0.2)' : 'none' }}>
            <Box display="flex">
              <img src="/sample1.png" style={{ width: "80px", height: "80px" }} />
            </Box>
            <Box pl={4} display="flex" flex="1 auto" flexDirection="column" alignItems="flex-start" justifyContent="center">
              <Typography variant="h6">L'étape du Tour - 2023</Typography>
              <Box>156km - 4100m D+</Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <RadioGroup
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel value="edt" control={<Radio />} />
              </RadioGroup>
            </Box>
          </Box>

          <Box onClick={() => setValue('bdm')} display="flex" sx={{ cursor: "pointer", border: "1px solid #51AFC9", marginBottom: "-1px", background: value === 'bdm' ? 'rgba(81, 175, 201, 0.2)' : 'none' }}>
            <Box display="flex">
              <img src="/sample2.png" style={{ width: "80px", height: "80px" }} />
            </Box>
            <Box pl={4} display="flex" flex="1 auto" flexDirection="column" alignItems="flex-start" justifyContent="center">
              <Typography variant="h6">{_t("40km+ by the sea")}</Typography>
              <Box>43km - 440m D+</Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <RadioGroup
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel value="bdm" control={<Radio />} />
              </RadioGroup>
            </Box>
          </Box>

          <Box onClick={() => setValue('ardeche')} display="flex" sx={{ cursor: "pointer", border: "1px solid #51AFC9", marginBottom: "-1px", background: value === 'ardeche' ? 'rgba(81, 175, 201, 0.2)' : 'none' }}>
            <Box display="flex">
              <img src="/sample3.png" style={{ width: "80px", height: "80px" }} />
            </Box>
            <Box pl={4} display="flex" flex="1 auto" flexDirection="column" alignItems="flex-start" justifyContent="center">
              <Typography variant="h6">{_t("Ride in Ardèche")}</Typography>
              <Box>34km - 123m D+</Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <RadioGroup
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel value="ardeche" control={<Radio />} />
              </RadioGroup>
            </Box>
          </Box>

        </Box>
        <Box mt={8} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <Button onClick={onImport} variant="contained" sx={{ color: "white" }}>{_t("Import")}</Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default Samples