import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Root = () => {
  return (
    <div>
      <h2>Root admin</h2>

      {/* <div>{error}</div>

      <h3>General Tab initial prompt</h3>
      <textarea
        placeholder='Enter the initial prompt for the General tab here.'
        className='RootTextarea'
        onChange={(e) => setPromptGeneral(e.target.value)}
        defaultValue={promptGeneral}
       />
      <button disabled={updating} onClick={() => onUpdate('prompt_general', promptGeneral)}>{updating ? 'Updating...' : 'Update'}</button>

      <h3>POI Tab initial prompt</h3>
      <textarea
        placeholder='Enter the initial prompt for the POI tab here.'
        className='RootTextarea'
        onChange={(e) => setPromptPoi(e.target.value)}
        defaultValue={promptPoi}
       />
      <button disabled={updating} onClick={() => onUpdate('prompt_poi', promptPoi)}>{updating ? 'Updating...' : 'Update'}</button>

      <h3>Nutrition Tab initial prompt</h3>
      <textarea
        placeholder='Enter the initial prompt for the nutrition tab here.'
        className='RootTextarea'
        onChange={(e) => setPromptNutrition(e.target.value)}
        defaultValue={promptNutrition}
       />
      <button disabled={updating} onClick={() => onUpdate('prompt_nutrition', promptNutrition)}>{updating ? 'Updating...' : 'Update'}</button>

      <h3>Packing Tab initial prompt</h3>
      <textarea
        placeholder='Enter the initial prompt for the packing tab here.'
        className='RootTextarea'
        onChange={(e) => setPromptPackging(e.target.value)}
        defaultValue={promptPackging}
       />
      <button disabled={updating} onClick={() => onUpdate('prompt_packing', promptPackging)}>{updating ? 'Updating...' : 'Update'}</button> */}

      <Box>
        <Button component={Link} to="/root/prompts">Gestion des prompts</Button>
      </Box>

      <Box>
        <Button component={Link} to="/root/roadbooks">Gestion des roadbooks</Button>
      </Box>

    </div>
  );
}

export default Root;
