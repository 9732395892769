import { Box, Typography } from '@mui/material';
import _t from '../utils/i18n';
import { useSelector } from 'react-redux';
import { selectUser } from '../selectors';

const getSideContents = (step) => {
  const sideContents = [
    {
      title: _t("Confidence in Personalization"),
      bullets: [
        {
          title: _t("Secure Data"),
          description: _t("Your information is encrypted and securely stored."),
          icon: "lock",
        },
        {
          title: _t("Tailored insights"),
          description: _t("Personal details enable accurate, relevant cycling advice."),
          icon: "target",
        },
      ],
    },
    {
      title: _t("Effortless Start"),
      bullets: [
        {
          title: _t("Quick Integration"),
          description: _t("Just import your gpx to get started."),
          icon: "strava",
        },
        {
          title: _t("Reliable"),
          description: _t("Trusted tools for accurate route planning."),
          icon: "upload",
        },
      ],
    },
    {
      title: _t("Precision matters"),
      bullets: [
        {
          title: _t("Accurate Planning"),
          description: _t("Details ensure your roadbook is finely tuned to your specific needs."),
          icon: "strava",
        },
        {
          title: _t("Real-Time Updates"),
          description: _t("Ride date informs timely weather forecasts for your journey."),
          icon: "upload",
        },
      ],
    },
    {
      title: _t("Value in Membership"),
      bullets: [
        {
          title: _t("Privacy First"),
          description: _t("We prioritize your data privacy and confidentiality."),
          icon: "strava",
        },
        {
          title: _t("Save & review"),
          description: _t("Account creation allows you to save and revisit your roadbooks anytime."),
          icon: "upload",
        },
      ],
    },
    {
      title: _t("Test now, personalize later"),
      bullets: [
        {
          description: _t("Try our GPX samples to get a feel for our AI-driven insights."),
        },
        {
          description: _t("It's a great start, but the real experience shines when using your own routes."),
        },
        {
          description: _t("After testing, upload your personal GPX file to truly elevate your rides."),
        },
      ],
    },
  ];

  return sideContents[step || 0];
};

const Layout = ({ children, step, editMode }) => {
  const sideContents = getSideContents(step);
  const user = useSelector(selectUser);

  if (editMode) return children;

  return (
    <Box className="IngestLayout" mt={5}
      sx={{
        display: "flex",
        width: "90vw",
        maxWidth: "1000px",
        background: "#ffffff",
      }}>

      <Box
        className="IngestContent"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={{ padding: { xs: "15px", md: "30px" }, width: '100%' }}
      >
        {children}
      </Box>

      {!user && (
        <Box
          className="IngestSide"
          flexDirection="column"
          sx={{
            padding: "30px",
            width: "400px",
            color: "white",
            background: "#51AFC9",
            display: { xs: 'none', md: 'flex' },
            textAlign: "left",
          }}
        >
          <Typography sx={{ fontWeight: "bolder", fontSize: "28px", margin: "15px 0 25px" }}>{sideContents.title}</Typography>

          <Box>
            {sideContents.bullets.map(({ title, description }, i) => (
              <Box
                key={i}
                sx={{ marginBottom: "20px" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
                <Typography>{description}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Layout;
