import Layout from "./Layout";
import _t from "../utils/i18n";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { API_BASE_URL } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectUserHash, tokenSelector } from "../selectors";
import dayjs from "dayjs";

const getCyclingMotivations = () => ([
  {
    id: 'fitness',
    description: _t("I want to get fit"),
  },
  {
    id: 'fun',
    description: _t("I want to have fun"),
  },
  {
    id: 'competition',
    description: _t("I want to compete"),
  },
  {
    id: 'exploration',
    description: _t("I want to explore new places"),
  },
  {
    id: 'pushing',
    description: _t("I want to push my limits"),
  },
  {
    id: 'other',
    description: _t("Other"),
  }
]);

const FirstTime = ({ editMode }) => {
  const dispatch = useDispatch();
  const userHash = useSelector(selectUserHash);
  const user = useSelector(selectUser);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const defaultBirthdate = user?.birthdate ? dayjs(user.birthdate).format("DD/MM/YYYY") : null;

  const [birthdate, setBirthdate] = useState(defaultBirthdate || '');
  const [gender, setGender] = useState(user?.gender || 'male');
  const [cyclingMotivation, setCyclingMotivation] = useState(user?.cycling_motivation || '');

  const access_token = useSelector(tokenSelector);

  useEffect(() => {
    if (!userHash) return;

    const fetchUser = async (hash) => {
      const url = API_BASE_URL
        .clone()
        .segment('users')
        .segment(hash)
        .toString();

      try {
        const response = await fetch(url);
        const user = await response.json();

        dispatch({ type: 'user/set', payload: user });
      } catch (e) {
        console.log('error', e);
      }
    };

    fetchUser(userHash);
  }, []);

  const onSave = async () => {
    setLoading(true);

    const url = API_BASE_URL
      .clone()
      .segment('users')
      .toString();

    // transform data.ride_date to YYYY-MM-DD
    // validate date format REGEX ^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$
    if (!birthdate.match(/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/)) {
      setError(_t("Invalid date format"));
      setLoading(false);
      return;
    }

    if (!cyclingMotivation.trim().length) {
      setError(_t("Invalid cycling motivation"));
      setLoading(false);
      return;
    }

    // user must be > 13 years old
    const dateParts = birthdate.split('/');
    const birthdateDate = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
    const now = new Date();

    if (now.getFullYear() - birthdateDate.getFullYear() < 13) {
      setError(_t("You must be at least 13 years old"));
      setLoading(false);
      return;
    }

    try {
      const headers = {
        'Content-Type': 'application/json',
      };

      if (access_token)
        headers.Authorization = `Bearer ${access_token}`;

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          birthdate,
          gender,
          cycling_motivation: cyclingMotivation,
        }),
      });

      if (!response.ok)
        throw new Error;

      const { user } = await response.json();

      dispatch({ type: 'userHash/set', payload: user.id });
      dispatch({ type: 'ingest/set',  payload: 'user_created' });
    } catch (e) {
      console.log('error', e);
      setError(_t("Creation failed"));
    }

    setLoading(false);
  };

  return (
    <Layout step={0}>
      <Box className="FirstTime" width="100%">

       {!editMode && (
          <Box className="Title" sx={{ textAlign: "left" }}>
            <Typography variant="h4">{_t("Let's personalize your experience")}</Typography>
            <Typography>{_t("A few details will help us tailor gpxplorer just for you.")}</Typography>
          </Box>
        )}

        <Box mt={4}>
          <TextField
            label={_t("Birthdate")}
            variant="outlined"
            required
            fullWidth
            placeholder="dd/mm/yyyy"
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
            helperText={!editMode && _t("Providing your birthdate helps us offer age-appropriate advice and recommendations for a safer and enjoyable riding experience.")}
          />
        </Box>
        <Box mt={4}>
          <FormControl fullWidth>
            <InputLabel id="gender">{_t("Gender")}*</InputLabel>
            <Select
              sx={{ textAlign: "left" }}
              value={gender}
              label="gender"
              required
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem value="male">{_t("Male")}</MenuItem>
              <MenuItem value="female">{_t("Female")}</MenuItem>
              <MenuItem value="other">{_t("Other")}</MenuItem>
            </Select>
            <FormHelperText>{!editMode && _t("Your gender helps us provide more accurate nutritional and physiological recommendations during your rides.")}</FormHelperText>
          </FormControl>
        </Box>
        <Box mt={4}>
          <FormControl fullWidth>
            <InputLabel id="motivation">{_t("Cycling motivation")}*</InputLabel>
            <Select
              sx={{ textAlign: "left" }}
              value={cyclingMotivation}
              label="motivation"
              required
              onChange={(e) => setCyclingMotivation(e.target.value)}
            >
              {getCyclingMotivations().map(({ id, description }) => (
                <MenuItem key={id} value={id}>{description}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{!editMode && _t("Understanding your motivations helps us tailor advice, insights, and recommendations that align with your cycling goals and aspirations.")}</FormHelperText>
          </FormControl>
        </Box>

        {error && (
          <Box mt={4}>
            <Typography variant="body1" sx={{ color: "red" }}>{error}</Typography>
          </Box>
        )}

        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            sx={{ color: "#fff", textTransform: "none" }}
            onClick={onSave}
            disabled={loading}
          >
            {editMode ? _t("Edit my settings") : _t("Generate my first roadbook")}
          </Button>
        </Box>

        {!editMode && (
          <Box mt={4}>
            <Typography sx={{ fontSize: "10px" }}>{_t("We respect your privacy and confidentiality. Information provided here is solely for personalizing your experience and is handled with utmost care and responsibility. For details, see our Privacy Policy.")}</Typography>
          </Box>
        )}

      </Box>
    </Layout>
  )
};

export default FirstTime;